import { Button, Modal, Popconfirm } from "antd";
import React, { useContext, useEffect, useState } from "react";
import CustomForm from "../CustomForm";
import { useForm } from "antd/es/form/Form";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FormButtonSave from "../FormButtonSave";
import { HomeContext } from "../../containers/Home";
import { getCrudaClass } from "../../utils/lookup_list";
import Emitter from "../../utils/emitter";
import { getWells } from "../../services/api-server/wells";
import { getOperations } from "../../services/api-server/operations";

function OperationConfigModal(props: any) {
	const context: any = useContext(HomeContext);

	// form related
	const [formRef] = useForm();
	const [formReset, setFormReset] = useState<boolean>(false);
	const [initialForm, setInitialForm] = useState<any>(null);
	const [activeRowKey, setActiveRowKey] = useState<any>(null);
	const [cancelPopover, setCancelPopover] = useState<boolean>(false);
	const [deletePopover, setDeletePopover] = useState<boolean>(false);

	const [operationInfo, setOperationInfo] = useState<any>(null);

	useEffect(() => {
		if (props.operationRecord) {
			resetForm(props.operationRecord);
			setActiveRowKey(props.operationRecord._id);
			setOperationInfo(props.operationRecord);
		}
	}, [props.operationRecord]);

	const resetForm = (values: any = null, reset: any = true) => {
		if (formRef) {
			setFormReset(true);
			reset && formRef.resetFields();
			formRef.setFieldsValue(values ? { ...values } : null);
			setInitialForm(values);
		}
	};

	const handleFormCancel = () => {
		props.setOpen(false);
		setCancelPopover(false);
		context?.handleFormUpdating(false, props.activeRowKey);
		if (operationInfo) {
			resetForm(operationInfo);
			setOperationInfo(operationInfo);
		} else {
			resetForm();
		}
	};

	const handleFormSave = (values: any) => {
		Object.keys(values).forEach((key) => {
			if (typeof values[key] == "string") {
				values[key] = values[key].trim();
			}
		});
		getCrudaClass("operation").then((cruda: any) => {
			console.log("activeRowKey of operation", activeRowKey);
			if (activeRowKey) {
				console.log("updating data", activeRowKey);
				const form = cruda.getFormList("operation");
				const formList: any = [];
				form.forEach((element: any) => {
					if (element.children && !("name" in element)) {
						element.children.forEach((child_element: any) => {
							if (child_element.name) formList.push(child_element.name);
						});
					} else {
						if (element.name) formList.push(element.name);
					}
				});

				cruda
					.updateLookupItem(
						"operation",
						props?.option,
						activeRowKey,
						context?.currentTenantKey,
						values,
						initialForm,
						{},
						props.user,
						formList
					)
					.then((updatedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully updated.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, activeRowKey);
						resetForm(values, true);
						context.setCurrentWell(updatedItem);
						Emitter.emit("forceUpdate", null);
						const newOperationData = operationInfo?.operation_data?.map(
							(item: any) => {
								if (item._id === updatedItem._id) {
									return updatedItem;
								}
								return item;
							}
						);
						const newRecord = {
							...operationInfo,
							operation_data: newOperationData,
						};

						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newOperationData },
						});
					})
					.catch((error: any) => {
						if (error?.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Operation already exists, please ensure the Operation ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			} else {
				console.log("adding data", props);
				cruda
					.addLookupItem(
						"operation",
						props?.option,
						values,
						{
							project_code: context?.currentProject?.project_code,
							well_id: operationInfo?.well_id,
						},
						context?.currentTenantKey,
						null,
						props.user
					)
					.then((addedItem: any) => {
						Emitter.emit("alert", {
							type: "success",
							message: `Operation has been successfully saved.`,
							description: "",
							top: true,
							closeable: false,
							timeout: 3000,
						});
						context?.handleFormUpdating(false, "new");
						resetForm(values, true);
						setActiveRowKey(
							addedItem?.uuid ||
								addedItem?.group_uuid ||
								addedItem?.tag_uuid ||
								addedItem?._id
						);
						Emitter.emit("forceUpdate", null);

						const newRecord = { ...operationInfo };
						const operationList = newRecord?.operation_data || [];
						operationList.push(addedItem);
						newRecord.operation_data = operationList;
						newRecord._id = addedItem._id;
						Emitter.emit("resetForm", newRecord);
						Emitter.emit("forceUpdate", {
							data: { operation_data: newRecord.operation_data },
						});
					})
					.catch((error: any) => {
						if (error.code === 11000) {
							Emitter.emit("alert", {
								type: "error",
								message: `This Operation already exists, please ensure the Operation ${
									Object.keys(error.keyPattern)[0]
								} is unique. Changes are not saved.`,
								description: "",
								top: true,
								closeable: false,
								timeout: 6000,
							});
						} else {
							Emitter.emit("alert", {
								type: "error",
								message: "Changes are not saved. Please try again.",
								description: "",
								top: true,
								closeable: false,
								timeout: 3000,
							});
						}
					});
			}
		});
	};

	const handleFormDelete = (values: any) => {
		context?.handleFormUpdating(false, activeRowKey);
		console.log("deleting data", activeRowKey);
		getCrudaClass("operation").then((cruda: any) => {
			cruda
				.deleteLookupItem(
					"operation",
					props?.option,
					activeRowKey,
					context?.currentTenantKey,
					{
						project_code: context?.currentProject?.project_code,
					},
					props.user
				)
				.then(() => {
					Emitter.emit("alert", {
						type: "success",
						message: `Operation has been successfully deleted.`,
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
					props.setOpen(false);
					setDeletePopover(false);
					setActiveRowKey(null);
					resetForm();
					Emitter.emit("forceUpdate", null);
					// Redirect to the selected page on delete
					if (props.redirectOnDelete) {
						props.redirectOnDelete();
					}
				})
				.catch(() => {
					Emitter.emit("alert", {
						type: "error",
						message: "Changes are not saved. Please try again.",
						description: "",
						top: true,
						closeable: false,
						timeout: 3000,
					});
				})
				.finally(() => {});
		});
	};

	return (
		<>
			<Modal
				closable={false}
				title={"Well - operation configuration"}
				open={props.open}
				centered
				destroyOnClose
				footer={() => {
					return (
						<>
							<Popconfirm
								placement="topLeft"
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											Permanently delete this well operation?
											<br />
											This action cannot be undone.
										</>
									</div>
								}
								open={deletePopover}
								//description="Permanently delete this item? This action cannot be undone."
								onConfirm={handleFormDelete}
								overlayClassName="popconfirm-danger"
								onCancel={() => {
									setDeletePopover(false);
								}}
								okText="Delete"
								okType="danger"
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								cancelText={<span>Cancel</span>}
								cancelButtonProps={{ ghost: true }}
							>
								<Button
									disabled={!activeRowKey}
									className="ant-btn-secondary"
									style={{ marginRight: "auto" }}
									danger
									onClick={() => {
										setDeletePopover(true);
									}}
								>
									Delete
								</Button>
							</Popconfirm>
							<Popconfirm
								icon={<ExclamationCircleOutlined style={{ color: "grey" }} />}
								title={
									<div style={{ maxWidth: "300px", padding: "10px" }}>
										<>
											You have unsaved changes. Discard them or continue
											editing?
										</>
									</div>
								}
								open={cancelPopover}
								placement="topRight"
								cancelButtonProps={{ ghost: true }}
								cancelText="Discard changes"
								onCancel={() => {
									setCancelPopover(false);
									setTimeout(() => {
										handleFormCancel();
									}, 300);
								}}
								okText="Continue editing"
								okType="default"
								onConfirm={() => {
									setCancelPopover(false);
								}}
							>
								<Button
									className="ant-btn-secondary"
									onClick={() => {
										if (formReset) {
											handleFormCancel();
										} else {
											setCancelPopover(true);
										}
									}}
								>
									Cancel
								</Button>
							</Popconfirm>
							<FormButtonSave
								activeKey={activeRowKey}
								form={formRef}
								handleFormSave={handleFormSave}
								formReset={formReset}
								setFormReset={setFormReset}
								// popconfirmTitle={
								// 	"Please note that once saved, the project template cannot be added or changed."
								// }
							/>
						</>
					);
				}}
			>
				<div style={{ height: "50vh" }}>
					<CustomForm
						tabKey={"operation"}
						formRef={formRef}
						setFormReset={setFormReset}
						dataOptions={props.extraData}
						activeRowKey={activeRowKey}
						activeRecord={props.operationRecord}
					/>
				</div>
			</Modal>
		</>
	);
}

export default OperationConfigModal;
