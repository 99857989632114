import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Input, Space, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CheckboxDropdown from "../components/CheckboxDropdown";
import { GetAntIcon } from "../utils/ant_icons";
import { setLookupData } from "../utils/lookupFinder";
import Map from "../components/Overview/Map";
import useHandleContextMenu from "../hooks/useHandleContextMenu";
import { useLocation, useNavigate } from "react-router";
import { HomeContext } from "./Home";
import Emitter from "../utils/emitter";
import { getCrudaClass } from "../utils/lookup_list";
import WellGallery from "../components/Wells/WellGallery";
import WellConfigurationModal from "../components/Modal/WellConfigurationModal";
import { getWells } from "../services/api-server/wells";

const { Text } = Typography;

function PWellOverview(props: any) {
	const context: any = useContext(HomeContext);
	const location = useLocation();
	const navigate = useNavigate();

	const [isLoading, setLoading] = useState<boolean>(true);
	const [activePage, setActivePage] = useState<any>("map");
	const [activeWells, setActiveWells] = useState<any>([]);

	const [openWellModal, setOpenWellModal] = useState<boolean>(false);

	//Map & Gallery states
	const [wellList, setWellList] = useState<any>(null);
	const [selectedWell, setSelectedWell] = useState<any>(null);
	const [selectedWellInfo, setSelectedWellInfo] = useState<any>(null);

	// Filtering states
	const [filteredWellList, setFilteredWellList] = useState<any>(null);
	const [filterMode, setFilterMode] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>("");
	const [descMode, setDescMode] = useState<boolean>(false);
	const [selectedFilters, setSelectedFilters] = useState<any>({
		location: [],
		well_purpose: [],
		well_status: [],
	});

	const [updated, setUpdated] = useState<boolean>(false);

	//Audit Logs states
	const [auditOpen, setAuditOpen] = useState<boolean>(false);

	const { handleContextMenu } = useHandleContextMenu();

	const sortData = (data: any) => {
		data.sort((a: any, b: any) => {
			if (a.label.toLowerCase() < b.label.toLowerCase()) {
				return -1;
			}
			if (a.label.toLowerCase() > b.label.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return data;
	};

	useEffect(() => {
		const handleWellModal = () => {
			setOpenWellModal(true);
		};

		const reloadWells = () => {
			setUpdated(!updated);
		};

		Emitter.on("wellModalOpen", handleWellModal);
		Emitter.on("reloadWells", reloadWells);

		return () => {
			Emitter.off("wellModalOpen", handleWellModal);
			Emitter.off("reloadWells", reloadWells);
		};
	}, []);

	//Fetch Physical Well List
	useEffect(() => {
		if ((context.currentTenantKey, context.allData)) {
			Emitter.emit("loading", true);

			if (localStorage.getItem("selectedFilters")) {
				let selectedFiltersStore: any = localStorage.getItem("selectedFilters");
				let selectedFiltersStoreJson = JSON.parse(selectedFiltersStore);
				setSelectedFilters(selectedFiltersStoreJson);
				setFilterMode(true);
			}

			getWells(context.currentTenantKey, {})
				.then((_data: any) => {
					// Data mapping in case need to add more fields
					const wellData = _data.map((item: any) => {
						return {
							...item,
						};
					});
					setWellList(wellData);
				})
				.catch((error: any) => {
					console.log(error);
				})
				.finally(() => {
					Emitter.emit("loading", false);
					setLoading(false);
				});
		}
	}, [updated, context.currentTenantKey, context.allData]);

	const selectWell = (wellKey: any, well: any) => {
		if (selectedWell != wellKey) {
			setSelectedWell(wellKey);
			setSelectedWellInfo(well);
			const selectedElement = document.getElementById(`project-${wellKey}`);

			setTimeout(() => {
				if (selectedElement) {
					selectedElement.scrollIntoView({ behavior: "smooth" });
				}
			}, 1);
		} else {
			setSelectedWell(null);
			setSelectedWellInfo(null);
		}
	};

	const hasSelectedFilters = () => {
		// Check if any of the filter categories have selected values
		return Object.values(selectedFilters).some(
			(values: any) => values.length > 0
		);
	};

	const filterRender = useMemo(() => {
		const handleMenuClick = (field: any, selectedValues: any[]) => {
			// console.log(`Selected: ${field} - ${selectedValues}`);
			// Update the selected filters state
			setSelectedFilters({ ...selectedFilters, [field]: selectedValues });

			localStorage.setItem(
				"selectedFilters",
				JSON.stringify({ ...selectedFilters, [field]: selectedValues })
			);
		};

		const handleClearAllFilters = () => {
			// Reset all filters to empty arrays
			setSelectedFilters({
				location: [],
				well_purpose: [],
				well_status: [],
			});
			setFilterMode(false);

			// Clear session storage for filter values and checkboxes
			localStorage.removeItem("selectedFilters");

			// Object.keys(selectedFilters).forEach((field) => {
			// 	const storageKey = generateStorageKey(field);
			// 	localStorage.removeItem(storageKey);
			// });

			setTimeout(() => {
				setFilterMode(true);
			}, 5);
		};

		return (
			<>
				<CheckboxDropdown
					options={[
						{
							label: "onshore",
							value: "onshore",
						},
						{ label: "offshore", value: "offshore" },
					]}
					onChange={(selectedValues) =>
						handleMenuClick("location", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters.location}
					Key="location"
					label="Location"
				/>
				<CheckboxDropdown
					options={context?.allData?.well_purpose?.map((wp: any) => ({
						label: wp.name,
						value: wp.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_purpose", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_purpose"]}
					Key="well_purpose"
					label="Well purpose"
				/>
				<CheckboxDropdown
					options={context?.allData?.well_status?.map((ws: any) => ({
						label: ws.name,
						value: ws.uuid,
					}))}
					onChange={(selectedValues) =>
						handleMenuClick("well_status", selectedValues)
					}
					onClear={""}
					defaultValue={selectedFilters["well_status"]}
					Key="well_status"
					label="Well status"
				/>
				<div className="test">
					<Button type="link" onClick={handleClearAllFilters}>
						Reset All
					</Button>
				</div>
			</>
		);
	}, [filterMode, selectedFilters]);

	const listWells = () => {
		const setWellContent = (currentWellData: any, infoKey: any) => {
			let content = "-";
			if (
				currentWellData[infoKey] ||
				infoKey == "latitude" ||
				infoKey == "longitude"
			) {
				content = setLookupData(context.allData, currentWellData, infoKey);
				if (infoKey === "location") {
					content =
						currentWellData[infoKey].charAt(0).toUpperCase() +
							currentWellData[infoKey].slice(1) || "-";
				}
			} else if (infoKey === "actual_start_date") {
				if (currentWellData?.actual_start_date) {
					var date = new Date(currentWellData.actual_start_date);
					// change the date format to "1 Mar 2024"
					content = date.toLocaleDateString("en-GB", {
						year: "numeric",
						month: "short",
						day: "numeric",
					});
				} else {
					content = "-";
				}
			}
			return content;
		};

		const renderPurpose = (
			wellProject: any,
			purpose: string,
			purposeType: string
		) => {
			console.log(wellProject?.[purposeType]);
			switch (purpose) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>
								{purpose} ({purposeType})
							</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New ({purposeType})</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Resolved ({purposeType})</span>
						</div>
					);
				default:
					if (purpose) {
						return (
							<div className="project-status-item status-counter-in-progress">
								<span>
									{setLookupData(context.allData, wellProject, purpose)}{" "}
									{wellProject?.[purposeType] && (
										<>
											(
											{setLookupData(context.allData, wellProject, purposeType)}
											)
										</>
									)}
								</span>
							</div>
						);
					} else {
						return <></>;
					}
			}
		};
		const renderStatus = (
			wellProject: any,
			status: string,
			statusType: string
		) => {
			switch (status) {
				case "In progress":
					return (
						<div className="project-status-item status-counter-in-progress">
							<span>In Progress</span>
						</div>
					);

				case "New":
					return (
						<div className="project-status-item status-counter-new">
							<span>New</span>
						</div>
					);

				case "Resolved":
					return (
						<div className="project-status-item status-counter-completed">
							<span>Resolved</span>
						</div>
					);
				default:
					if (status) {
						return (
							<div className="project-status-item status-counter-in-progress">
								<span>
									{setLookupData(context.allData, wellProject, status)}{" "}
									{wellProject?.[statusType] && (
										<>
											({setLookupData(context.allData, wellProject, statusType)}
											)
										</>
									)}
								</span>
							</div>
						);
					} else {
						return <></>;
					}
			}
		};

		const renderDetail = (wellProject: any) => {
			const showDetailInfo = () => {
				const infoKeyList: any = [
					{ label: "License", key: "license" },
					{ label: "Well operator", key: "well_operator_id" },
					{ label: "Block", key: "block" },
					{ label: "Field", key: "field" },
					{ label: "Water depth", key: "water_depth" },
					{ label: "Location", key: "location" },
					{ label: "Latitude", key: "latitude" },
					{ label: "Longitude", key: "longitude" },
				];

				return (
					<>
						{infoKeyList.map((infoKey: any) => {
							return (
								<Space direction="vertical" size={0}>
									<span style={{ opacity: 0.45, fontSize: "12px" }}>
										{infoKey.label}
									</span>
									<div
										style={{
											display: "flex",
											justifyContent: "flex-start",
											alignItems: "center",
											fontSize: "14px",
											minWidth: "100%",
											width: "0",
										}}
									>
										<Text
											title=""
											style={
												infoKey.key == "country"
													? {
															whiteSpace: "nowrap",
															overflow: "hidden",
															textOverflow: "ellipsis",
													  }
													: {}
											}
											ellipsis={{
												tooltip: (
													<span>
														{setWellContent(wellProject, infoKey.key)}
													</span>
												),
											}}
										>
											<span
												style={
													infoKey.key == "country"
														? {
																whiteSpace: "nowrap",
																overflow: "hidden",
																textOverflow: "ellipsis",
														  }
														: {}
												}
											>
												{setWellContent(wellProject, infoKey.key)}
											</span>
										</Text>
									</div>
								</Space>
							);
						})}
					</>
				);
			};

			return (
				<div className="detailed-card">
					<Space direction="vertical" style={{ width: "100%" }} size={15}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span className="detailed-card-label">Well name</span>
							<div style={{ fontSize: "20px", lineHeight: "25px" }}>
								<span
									className={"project-title-clickable detailed-card-title"}
									onClick={() => {
										navigate(`${wellProject._id}`, {
											replace: false,
											state: {
												...wellProject,
												state: { ...wellProject },
											},
										});
									}}
									onContextMenu={(event: any) => {
										const location = `${window.location.href}/${wellProject._id}`;
										handleContextMenu(event, { link: location });
									}}
								>
									{wellProject.well_name}
								</span>
							</div>
						</Space>
						<div className="project-status">
							{renderPurpose(wellProject, "well_purpose", "well_purpose_type")}
							{renderStatus(wellProject, "well_status", "well_status_type")}
						</div>
						<div className="project-grid-container">{showDetailInfo()}</div>
					</Space>
				</div>
			);
		};

		const renderCompact = (wellProject: any) => {
			return (
				<div className="project-mini-container">
					<Space direction="vertical" size={9}>
						<Space
							direction="vertical"
							size={0}
							style={{ display: "flex", flexDirection: "column" }}
						>
							<span style={{ opacity: 0.45, fontSize: "12px" }}>Well name</span>
							<span
								className={"project-title-clickable"}
								style={{
									whiteSpace: "normal",
									display: "inline",
									overflowWrap: "break-word",
								}}
								onClick={() => {
									navigate(wellProject._id, {
										replace: false,
										state: { ...wellProject },
									});
								}}
								onContextMenu={(event: any) => {
									const location = `${window.location.href}/${wellProject._id}`;
									handleContextMenu(event, { link: location });
								}}
							>
								{wellProject?.well_name}
							</span>
						</Space>
						<div className="project-status">
							{renderPurpose(wellProject, "well_purpose", "well_purpose_type")}
							{"  "}
							{renderStatus(wellProject, "well_status", "well_status_type")}
						</div>
					</Space>
				</div>
			);
		};

		let currentWellList = filteredWellList || wellList;
		if (currentWellList.length > 0) {
			return currentWellList?.map((element: any) => {
				return (
					<div
						id={`project-${element._id}`}
						className={`overview-card-list-container`}
						onClick={() => {
							selectWell(element._id, element);
						}}
					>
						{element._id === selectedWell ? (
							<>{renderDetail(element)}</>
						) : (
							<>{renderCompact(element)}</>
						)}
					</div>
				);
			});
		} else {
			return <span>No data found.</span>;
		}
	};

	//Render
	return (
		<>
			{!isLoading && (
				<div className="generic-container" style={{ overflow: "auto" }}>
					<div
						className="main-container"
						style={{
							minHeight: "100%",
							flex: 1,
							width: "100%",
							maxHeight: "100%",
							overflow: "hidden",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div className="project-header">
							<div
								style={{ display: "flex", flexDirection: "row", gap: "10px" }}
							>
								<div
									className={
										activePage === "map" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("map");
									}}
								>
									{GetAntIcon("aim")}
									<span>Map overview</span>
								</div>{" "}
								<div
									className={
										activePage === "gallery" ? "project-active-button" : ""
									}
									style={{
										display: "flex",
										padding: "4px 15px",
										gap: "8px",
										cursor: "pointer",
									}}
									onClick={() => {
										setActivePage("gallery");
									}}
								>
									{GetAntIcon("app")}
									<span>Gallery</span>
								</div>
							</div>

							{/* <div
								style={{
									display: "flex",
									flex: "1",
									justifyContent: "flex-end",
									alignItems: "center",
									gap: "15px",
								}}
							>
								<Input
									placeholder="Search"
									className="input-clearable"
									style={{ maxWidth: "265px" }}
									onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
										setSearchValue(e.currentTarget.value);
										e.currentTarget.blur();
									}}
									onChange={(e) => {
										if (e.target.value === "") {
											setSearchValue("");
										}
									}}
									allowClear
									prefix={<SearchOutlined />}
								/>
								<div style={{ display: "flex", flexDirection: "row" }}>
									{" "}
									<div
										className={
											filterMode || hasSelectedFilters()
												? "project-active-button"
												: ""
										}
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setFilterMode(!filterMode);
										}}
									>
										{GetAntIcon("filter")}
									</div>
									<div
										style={{ padding: "4px 10px", cursor: "pointer" }}
										onClick={() => {
											setDescMode(!descMode);
										}}
									>
										{descMode
											? GetAntIcon("descending")
											: GetAntIcon("ascending")}
									</div>
								</div>
							</div> */}
						</div>

						{activePage == "map" && (
							<div className="overview-content">
								<div
									className={`filter-area ${
										filterMode ? "show-filter" : "hide-filter"
									}`}
								>
									{filterMode && filterRender}
								</div>
								<div className="overview-map">
									<Map
										projectList={filteredWellList || wellList}
										selectProject={selectWell}
										selectedProject={selectedWell}
										selectedProjectInfo={selectedWellInfo}
										keyType={"_id"}
									></Map>
									<div className="sub-container">
										<div className="project-list-container">
											{wellList?.length > 0 ? (
												<Space className="project-list" direction="vertical">
													{listWells()}
												</Space>
											) : (
												<Space className="project-list" direction="vertical">
													<span>There are no wells available.</span>
												</Space>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
						{activePage == "gallery" && (
							<div
								className="generic-content project-overview-container"
								style={{
									flex: 1,
									display: "flex",
									flexWrap: "inherit",
									flexDirection: "column",
									alignItems: "flex-start",
									padding: 0,
									gap: 0,
									overflowX: "auto",
									maxHeight: "100%",
								}}
							>
								<WellGallery
									userRole={props?.params.userRole}
									addItemPrivilege={["project-manager"]}
									allItems={wellList}
									activeItems={activeWells}
									setActiveItems={setActiveWells}
								/>
							</div>
						)}
					</div>

					<WellConfigurationModal
						open={openWellModal}
						setOpen={setOpenWellModal}
						extraData={context.allData}
						user={props.params?.user}
					/>
				</div>
			)}
		</>
	);
}

export default PWellOverview;
